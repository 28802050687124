import config from '../../../config/config'
// import axios from 'axios'
import { axios } from '../../../api/index'

const baseUrl = config.baseUrl + '/question/api/v1'
const questionUrl = config.questionUrl + '/question/api/v1'
const totalUrl = config.totalUrl + '/question/api/v1'
const examUrl = config.baseUrl + '/exam/api/v1'
const courseUrl = config.baseUrl + '/course/api/v1'
export default {
  getQuestionsStatistic(id) {
    return axios.get(baseUrl + `/statistics/exam/questions/all?examId=${id}`)
  },
  getExamStatistic(id) {
    return axios.get(baseUrl + `/statistics/exam/knowledge?examId=${id}`)
  },
  getQuestionA(p, s, k) {
    return axios.get(baseUrl + `/statistics/all/questions?page=${p}&size=${s}&questionName=${k}`)
  },
  getExamQ(id, p, s, k) {
    return axios.get(baseUrl + `/statistics/exam/questions?examId=${id}&page=${p}&size=${s}&questionName=${k}`)
  },
  getExamA(p, s, k, t) {
    return axios.get(examUrl + `/statistic/exam/list?page=${p}&size=${s}&examName=${k}&type=${t}`)
  },
  getCourseADetail(id) {
    return axios.get(courseUrl + `/statistic/study/course?courseId=${id}`)
  },
  getCourseA(p, s, k) {
    return axios.get(courseUrl + `/statistic/study/course/list?pageNumber=${p}&pageSize=${s}&content=${k}`)
  },
  getExamList(id, page, size, k) {
    return axios.get(examUrl + `/statistic/user/exam/list?userId=${id}&page=${page}&size=${size}&examName=${k}`)
  },
  getStudyDetail(id) {
    return axios.get(courseUrl + `/course/member/progress/details/${id}`)
  },
  getCourseList(id, page, size, keyword) {
    return axios.get(courseUrl + `/statistic/user/study/course/list?userId=${id}&pageNumber=${page}&pageSize=${size}&content=${keyword}`)
  },
  getUserStudy(id, date) {
    return axios.get(courseUrl + `/statistic/user/study?userId=${id}&date=${date}`)
  },
  getUserAbs(id) {
    return axios.get(examUrl + `/public/user/abilities?userId=${id}`)
  },
  getAllScore(id) {
    return axios.get(examUrl + `/statistic/user/all/score?userId=${id}`)
  },
  getCountByT(id) {
    return axios.get(baseUrl + `/statistics/user/do-amount/question-type?userId=${id}`)
  },
  getCountByK(id) {
    return axios.get(baseUrl + `/statistics/user/do-amount/knowledge-point?userId=${id}`)
  },
  getDaysQuestionCount(id, s, e) {
    return axios.get(baseUrl + `/statistics/user/do-amount/date?userId=${id}&startDate=${s}&endDate=${e}`)
  },
  getCountByDifficult(id) {
    return axios.get(baseUrl + `/statistics/user/do-amount/difficulty?userId=${id}`)
  },
  getUserAb(id) {
    return axios.get(examUrl + `/statistic/user/rank-and-ability?userId=${id}`)
  },
  getMyQuestionBanks(searchContent = '', sort = '', page = 0, size = 10) {
    return axios.get(`${baseUrl}/questionBanks/byEnterprise?page=${page}&size=${size}&searchContent=${searchContent}&sort=${sort}`)
  },
  getOpenQuestionBanks(sort = '', page = 0, size = 10, searchDTO) {
    return axios.post(`${baseUrl}/questionBanks/search?page=${page}&size=${size}&sort=${sort}`, searchDTO)
  },
  getOpenQuestionBanksBymanual(sort = '', page = 0, size = 999, searchDTO) {
    // 手动出卷
    return axios.post(`${baseUrl}/questionBanks/search?page=${page}&size=${size}&sort=${sort}`, searchDTO)
  },
  // 通过关键字搜索题目
  getQuestionByKeyword(page, size, content) {
    return axios.get(`${questionUrl}/questions/search/key-word?page=${page}&size=${size}&content=${content}`)
  },
  addQuestionBank(questionBank) {
    return axios.post(`${baseUrl}/questionBanks/add`, questionBank)
  },
  modifyQuestionBank(questionBank) {
    return axios.post(`${baseUrl}/questionBanks/${questionBank.questionBankId}/modify`, questionBank)
  },
  deleteQuestionBank(id) {
    return axios.post(`${baseUrl}/questionBanks/${id}/delete`)
  },
  forkQuestionBank(id) {
    return axios.post(`${baseUrl}/questionBanks/${id}/fork`)
  },

  questionSearch(questionBankId) {
    return axios.post(`${baseUrl}/questions/search?questionBankId=${questionBankId}`, {})
  },

  searchQuestionsWithAnswer(questionBankId, page, size, keyword) {
    return axios.get(`${baseUrl}/public/searchQuestionsWithAnswer?questionBankId=${questionBankId}&page=${page}&size=${size}&keyword=${keyword}`)
  },

  searchQuestionsByKnownOrTagId(knowledgeId, searchContent, sort = 'asc', page, size, tagId) {
    if (tagId === 1) {
      return axios.get(
        `${baseUrl}/questions/get/by-knowledge?knowledgeId=${knowledgeId}&searchContent=${searchContent}&sort=${sort}&page=${page}&size=${size}`
      )
    } else if (tagId === 2) {
      return axios.get(`${baseUrl}/questions/get/by-tag?tagId=${knowledgeId}&searchContent=${searchContent}&sort=${sort}&page=${page}&size=${size}`)
    }
  },

  getFileToken(folderId, name) {
    return axios.get(`file/api/v1/oss/token/file?folderId=${folderId}&name=${name}`)
  },
  /**
   * 获取题库列表中，包含特定知识点的题目的可用数目
   * @param questionBankIds
   * @param knowledgePointIds
   * @returns {AxiosPromise<any>}
   */
  getQuestionAvailableNum(questionBankIds, knowledgePointIds) {
    const payload = {
      questionBankIds,
      knowledgePointIds,
      questionTypes: [0, 1, 2, 3, 4]
    }
    return axios.post(`${baseUrl}/questions/num`, payload)
  },
  /**
   * 获取企业下的题目总数
   * @returns {AxiosPromise<any>}
   */
  getQuestionCountStatistic() {
    return axios.get(`${baseUrl}/statistics/sum/question`)
  },
  getTotal() {
    return axios.get(`${baseUrl}/questions/get/total/count`)
  },
  /**
   * 试卷库手动出卷获取知识点(tree结构)
   */
  getKnownLedgeTree() {
    return axios.get(`${baseUrl}/questions/knowledge/tree/with-count`)
  },
  getKnownLedgeTree1(id) {
    return axios.get(`${baseUrl}/questions/knowledge/tree/with-count?questionBankId=${id}`)
  },
  /**
   * 手动出卷标签(tree结构)
   */
  getTagTree() {
    return axios.get(`${baseUrl}/questions/org-tag/with-count`)
  },
  /**
   * 自动出卷
   */
  autoMaticExam(payload) {
    return axios.post(`${baseUrl}/auto/question/get`, payload)
  },
  //题库获取题目
  questionListById(payload) {
    return axios.get(`${baseUrl}/questions/search/with-answer?questionBankId=${payload.questionBankId}&page=${payload.page}&size=${payload.size}`)
  },
  // 默认模板
  getDefaultTemplate() {
    return axios.get(`${baseUrl}/questions/default/template`)
  }
}
