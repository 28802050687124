<template>
  <div>
    <Modal v-model="show" :width="60" :transfer="false" @on-cancel="close" :mask-closable="false">
      <section slot="header">
        <h2>选题</h2>
      </section>
      <section style="display: flex;flex-direction: column;flex: 1;">
        <Form ref="paperForm" :model="paperObject" label-position="right">
          <!-- <FormItem label="题库类型选择">
            <RadioGroup v-model="paperObject.type">
              <Radio label="mine">我的题库</Radio>
              <Radio label="star">收藏题库</Radio>
              <Radio label="random">公共题库</Radio>
            </RadioGroup>
          </FormItem> -->

          <FormItem label="题库选择" prop="questionBank">
            <Select v-model="paperObject.questionBank" style="width:260px" @on-change="handleQuestionBankChange" filterable>
              <Option v-for="(item, index) in questionBankList" :key="`item-${index}`" :value="item.id || -1">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem label="知识点">
            <el-button size="small" type="parmary" @click="drawer = true">展开</el-button>
            <span>&nbsp;&nbsp;已选{{ searchFormItem.knowledgePoints.length }}个知识点</span>
          </FormItem>

          <!-- <FormItem label="题型">
            <RadioGroup v-model="paperObject.questionType" type="button">
              <Radio v-for="item in questionTypeList" :label="item" :key="item">{{ item }}</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="难易程度">
            <RadioGroup v-model="paperObject.difficulty" type="button">
              <Radio v-for="item in difficulty" :label="item" :key="item">{{ item }}</Radio>
            </RadioGroup>
          </FormItem> -->

          <!-- <FormItem label="知识点选择">
            <Select v-model="paperObject.knowledgePoint" multiple style="width:260px">
              <Option v-for="item in knowledgePointList" :key="item" :value="item">{{ item }}</Option>
            </Select>
          </FormItem> -->
        </Form>
        <hr />
        <div style="width: 100%;overflow-y: auto;flex: 1 1 auto;">
          <section v-if="questionList.data.length === 0 && !loadingQuestion" style="padding: 20px">
            当前题库没有题目 / 没有选择题库
          </section>
          <section v-if="loadingQuestion" style="padding: 20px">
            加载中 ...
          </section>
          <div v-if="questionList.data.length > 0 && !loadingQuestion">
            <section v-for="(item, index) in questionList.data" :key="`item1-${index}`" class="question-item question-image-size-restrict">
              <div class="stem">
                <div>{{ index + 1 }}.</div>
                <BaseQuestion class="baseQ" style="width: calc(100% - 17px);" :question="item" />
              </div>
              <div class="question-item-footer">
                <div style="width: 350px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                  知识点:
                  <span v-for="(knowledgePoint, kIndex) in item.knowledgePoints" :key="`knowledgePoint-${kIndex}`"
                    >{{ knowledgePoint.knowledgeName }}{{ kIndex !== item.knowledgePoints.length - 1 ? '、 ' : '' }}</span
                  >
                </div>
                <div class="question-item-footer-right">
                  <span>难易度: {{ ytConstant.difficultType.getLabel(item.difficulty) }}</span>
                  <span>组卷次数: {{ item.useCount }}</span>
                  <Button v-if="_paperHasQuestion(item.questionId)" type="dashed" class="extra-small-btn" @click="handleSelect(item)"
                    >取消选题</Button
                  >
                  <Button v-else type="primary" class="extra-small-btn" @click="handleSelect(item)">选题</Button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <el-pagination
        background
        @size-change="changePageSize"
        @current-change="changePage"
        layout="total, prev, pager, next, sizes"
        :page-sizes="[10, 20, 40, 100]"
        :page-size.sync="pageSize"
        style="text-align: right;margin-top: 10px;"
        :total="questionList.total"
      />

      <div slot="footer">
        <div slot="footer">
          <Button @click="close" class="modal-btn">取消</Button>
          <Button type="primary" class="modal-btn" @click="handleFinish">确定</Button>
        </div>
      </div>
    </Modal>
    <Drawer style="z-index: 1500" class="drawer" title="知识点" :closable="false" v-model="drawer">
      <el-tree
        style="height: calc(100% - 105px);width: 100%;overflow: auto;"
        :filter-node-method="filterNode"
        node-key="id"
        ref="sModalTree"
        @check-change="val => handleCheckChange(val, true)"
        :data="knowledgePointTree"
        show-checkbox
      >
        <div slot-scope="{ node, data }" style="display: flex;font-size: 14px">
          <div class="knowLStyle">{{ data.name }}</div>
          <el-tooltip>
            <div slot="content">总题量: {{ data.questionCount }}<br />该知识点题量:{{ data.knowQuestionCount }}</div>
            <div style="color: #568FEB;margin-left: 8px">({{ data.questionCount }} - {{ data.knowQuestionCount }})</div>
          </el-tooltip>
        </div>
      </el-tree>
      <div style="margin: 20px 0;text-align: center;">
        <el-button
          size="mini"
          type="danger"
          @click="
            drawer = false
            $refs.sModalTree.setCheckedKeys([])
            searchFormItem.knowledgePoints = []
            reset()
            searchQuestions()
          "
          >清空</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="
            drawer = false
            searchQuestion()
          "
          >搜索</el-button
        >
      </div>
    </Drawer>
  </div>
</template>

<script>
import QuestionAPI from '@components/common/old/questionOld'
import BaseQuestion from '../../../common/questions/BaseQuestion'
import knowledge from '@api/knowledge'
import questionRepo from '../../../../api/questionRepo'
import questionApi from '@components/common/old/questionOld'

export default {
  components: {
    BaseQuestion
  },
  props: {
    // 试卷 section 的列表，方便数据同步和提交返回
    dataList: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.fetchQuestionBanks()
    // this.getKnowedge()
  },
  computed: {
    flatQuestionList() {
      const ret = []
      this.dataList.forEach(section => {
        if (section.questionVOS && section.questionVOS instanceof Array) {
          Array.prototype.push.apply(ret, section.questionVOS)
        }
      })
      return ret
    }
  },
  data() {
    return {
      keyword: '',
      show: this.value,
      loadingQuestion: false,
      drawer: false,
      knowledgePointTree: [],
      paperObject: {
        name: '',
        type: 'mine',
        questionType: '全部',
        difficulty: '容易',
        questionBank: ''
      },
      questionBankList: ['Java题库', 'C++ 题库', 'Nodejs 题库', '操作系统'],
      // questionTypeList: ['全部', '单选题', '多选题', '填空题', '代码题'],
      // knowledgePointList: ['知识点1', '知识点2'],
      // difficulty: ['容易', '普通', '困难'],
      questionList: {
        total: 0,
        data: []
      },
      pageNumber: 0,
      pageSize: 10,
      selectedQuestion: [],
      searchFormItem: {
        questionTag: [],
        keyWord: null,
        questionType: null,
        difficulty: null,
        dateRange: null,
        knowledgePoints: [],
        upLoader: null,
        correctRateMin: null,
        correctRateMax: null
      }
    }
  },
  methods: {
    searchQuestions() {
      this.loadingQuestion = true
      QuestionAPI.searchQuestionsWithAnswer(this.paperObject.questionBank, this.pageNumber, this.pageSize, this.keyword)
        .then(({ res }) => {
          res.data = res.data.map(question => {
            question.questionId = question.id
            return question
          })

          this.questionList = res
          this.getKnowedge()
        })
        .finally(() => {
          this.loadingQuestion = false
        })
    },
    reset() {
      this.pageNumber = 0
      this.pageSize = 10
    },
    searchQuestion() {
      this.loadingQuestion = true
      let ids = []
      this.searchFormItem.knowledgePoints.forEach(s => {
        ids.push(s.id)
      })
      let data = {
        authorName: null,
        difficulty: null,
        endTime: null,
        keyWord: null,
        questionTagIds: null,
        lowerRate: null,
        questionType: null,
        startTime: null,
        upperRate: null,
        status: null,
        knowledgePointIds: ids
      }
      questionRepo
        .searchAdvanced(this.paperObject.questionBank, this.pageNumber, this.pageSize, data)
        .then(({ res }) => {
          res.data = res.data.map(question => {
            question.questionId = question.id
            return question
          })

          this.questionList = res
          this.loadingQuestion = false
        })
        .catch(() => {
          this.loadingQuestion = false
        })
    },
    handleCheckChange(data, search) {
      if (search) {
        this.searchFormItem.knowledgePoints = this.$refs.sModalTree.getCheckedNodes()
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    getKnowedge() {
      questionApi.getKnownLedgeTree1(this.paperObject.questionBank).then(res => {
        this.sortData(res.res)
      })
    },
    sortData(data) {
      this.knowledgePointTree = data.filter(item => {
        if (item.status === false) {
          return item
        }
      })
      data.forEach(d => {
        // d.label = `${d.name}(${d.questionCount})`
        d.label = d.name
        if (data.children) {
          this.sortData(data.children)
        }
      })
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.selectedQuestion = []
      // this.$refs.paperForm.resetFields()
      // this.questionList.data = []
      this.questionList.total = 0
    },
    handleFinish() {
      this.$emit('finish', this.selectedQuestion, true)
      this.close()
    },
    fetchQuestionBanks() {
      //todo 请求 200 个，待优化，需后端提供接口
      QuestionAPI.getMyQuestionBanks('', '', 0, 200).then(({ res }) => {
        this.paperObject.questionBank = res.data[0].questionBankId
        this.searchQuestions()
        this.questionBankList = res.data.map(item => ({
          name: item.name,
          id: item.questionBankId
        }))
      })
    },
    handleQuestionBankChange(value) {
      if (!value) {
        return
      }
      this.pageSize = 10
      this.searchQuestions()
    },
    handleSelect(question) {
      if (!this._paperHasQuestion(question.questionId)) {
        // 初始选题默认10分/题
        question.points = 10
        this._addQuestionToPaper(question)
      } else {
        this._deleteQuestionFromPaper(question)
      }
      this.$emit('on-question-select')
    },
    _paperHasQuestion(questionId) {
      const ret = this.flatQuestionList.map(questionItem => questionItem.questionId).indexOf(questionId) !== -1
      return ret
    },
    _addQuestionToPaper(question) {
      // 默认新题加到第一个 section
      this.dataList[0].questionVOS.push(question)
    },
    _deleteQuestionFromPaper(question) {
      const { questionId } = question
      this.dataList.forEach((section, sIndex) => {
        section.questionVOS instanceof Array &&
          section.questionVOS.forEach((question, qIndex) => {
            if (question.questionId === questionId) this.dataList[sIndex].questionVOS.splice(qIndex, 1)
          })
      })
    },
    isQuestionSelected(questionId) {
      const ids = this.selectedQuestion.map(question => question.questionId)
      return ids.indexOf(questionId) !== -1
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.searchQuestion()
    },
    changePageSize(size) {
      this.pageNumber = 0
      this.pageSize = size
      this.searchQuestion()
    }
  },
  watch: {
    value(val) {
      this.show = val
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables.less';
@import '../../../../theme/knowledge';

::v-deep .ivu-modal {
  height: 92%;

  .ivu-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ivu-modal-body {
      flex: 1 1 auto;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
    }
  }
}

.question-item {
  // height: 50px;
  border-bottom: solid 1px @separate-line-color;
  padding: 10px;

  .stem {
    display: flex;
  }
}
.question-item-desc {
  text-align: left;
}
.question-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-item-footer-right * + * {
  margin-left: 20px;
}
.question-item-footer-right span {
  color: #bdc3cb;
}
.extra-small-btn {
  font-size: 12px;
  // padding: 1px;
}
/deep/ .ivu-modal-wrap {
  z-index: 1000 !important;
}
/deep/ .ivu-modal-mask {
  z-index: 1000 !important;
}
.baseQ {
  /deep/ .quesStyle {
    max-width: none !important;
  }
  /deep/ pre {
    white-space: normal;
  }
}
::v-deep .el-tree .el-tree-node__children {
  overflow: visible !important;
}
</style>
