import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl
export default {
  getBriefStem(id, data) {
    return axios.post(baseUrl + `/question/api/v1/questions/brief-stem?questionBankId=${id}`, data)
  },
  getTags() {
    return axios.get(baseUrl + '/question/api/v1/questions/tags')
  },
  addKnowledge(q, k) {
    return axios.post(baseUrl + `/question/api/v1/questions/questions-knowledgePoints/add?questionIds=${q}&knowledgeIds=${k}`)
  },
  clearK(q) {
    return axios.post(baseUrl + `/question/api/v1/questions/questions-knowledgePoints/add?questionIds=${q}`)
  },
  downloadModelUrl: `${baseUrl}/question/api/v1/files/download/excel`,
  uploadQuestion(questionBankId, file) {
    return axios.post(`${baseUrl}/question/api/v1/questions/upload/excel?questionBankId=${questionBankId}`, file)
  },
  copyQuestion(copyData) {
    return axios.post(baseUrl + '/question/api/v1/questions/copy', copyData)
  },
  moveQuestions(toQuestionBankId, questionIds) {
    return axios.post(`${baseUrl}/question/api/v1/questions/move?toQuestionBankId=${toQuestionBankId}`, questionIds)
  },
  addQuestion(questions) {
    return axios.post(baseUrl + '/question/api/v1/questions/add', questions)
  },
  deleteQuestion(id) {
    return axios.post(`${baseUrl}/question/api/v1/questions/${id}/delete`)
  },
  deleteQuestions(questionIds) {
    return axios.post(baseUrl + '/question/api/v1/questions/delete', questionIds)
  },
  getQuestionInfo(id) {
    return axios.get(`${baseUrl}/question/api/v1/questions/detail/${id}`)
  },
  modifyQuestion(id, questions) {
    return axios.post(`${baseUrl}/question/api/v1/questions/${id}/modify`, questions)
  },
  searchAdvanced(questionBankId, page, size, data) {
    return axios.post(`${baseUrl}/question/api/v1/questions/search?questionBankId=${questionBankId}&page=${page}&size=${size}`, data)
  },
  searchQuestion(page, size, keyword) {
    return axios.get(`${baseUrl}/question/api/v1/questions/search/key-word?page=${page}&size=${size}&content=${keyword}`)
  },
  addStaff(data) {
    return axios.post(baseUrl + '/user/api/v1/staff/add', data)
  },
  deleteStaff(data) {
    return axios.post(baseUrl + '/user/api/v1/staff/delete/batch', data)
  },
  getDeletedStaff() {
    return axios.get(baseUrl + '/user/api/v1/staff/staffs/deleted')
  },
  deleteShiftStaff(data) {
    return axios.post(baseUrl + '/user/api/v1/staff/delete/shift/batch', data)
  },
  restoreStaff(data) {
    return axios.post(baseUrl + '/user/api/v1/staff/restore/batch', data)
  }
}
